import React from "react";
import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <>
      <hr className={styles.divider} />
      <p className={styles.footer}>
        WeSifted exists to empower. To arm small and medium-sized businesses with clarity in a chaotic world. We cut through the noise. We deliver what matters. We work on donations, not profits. If our newsletters bring value to your life, consider donating. Help us sift for you!
      </p>
    </>
  );
}; 