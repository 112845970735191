import React, { useRef, useEffect } from "react";
import { generateNoise } from "../../utils/noiseGen";
import warpNoise from "../../utils/warpNoise";
import styles from "./AnimatedBackground.module.css";

export const AnimatedBackground = ({ children }) => {
  const containerRef = useRef(null);
  const animationFrameRef = useRef(null);
  const mousePos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const width = 256;
    const height = 256;
    // Create canvas once
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    
    // Create base64 URL once
    const baseCanvas = document.createElement("canvas");
    baseCanvas.width = width;
    baseCanvas.height = height;
    const baseCtx = baseCanvas.getContext("2d");

    let offset = 0;
    let rotationOffset = 0; // New offset for rotational movement
    let lastTime = 0;
    const fps = 60;
    const frameInterval = 1000 / fps;

    // Set initial background once
    if (containerRef.current) {
      containerRef.current.style.transform = 'translateZ(0)';
    } 

    function updateCanvasNoise(context, noiseData, width, height) {
      const imageData = context.createImageData(width, height);
      const pixels = new Uint32Array(imageData.data.buffer);
      
      const alpha = 128 << 24;
      for (let i = 0; i < width * height; i++) {
        const value = Math.floor(noiseData[Math.floor(i / width)][i % width] * 255);
        pixels[i] = alpha | (value << 16) | (value << 8) | value;
      }
      
      context.putImageData(imageData, 0, 0);
    }

    // Add mouse move handler
    const handleMouseMove = (e) => {
      const rect = containerRef.current.getBoundingClientRect();
      mousePos.current = {
        x: (e.clientX - rect.left) * (width / rect.width),
        y: (e.clientY - rect.top) * (height / rect.height)
      };
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('mousemove', handleMouseMove);
    }

    function animate(currentTime) {
      if (currentTime - lastTime < frameInterval) {
        animationFrameRef.current = requestAnimationFrame(animate);
        return;
      }

      const deltaTime = (currentTime - lastTime) / 1000;
      lastTime = currentTime;

      // Create more complex movement patterns
      offset += 0.008 * deltaTime * fps;
      rotationOffset += 0.005 * deltaTime * fps;

      // Vary the noise parameters over time
      const scale = 100 + Math.sin(offset * 0.3) * 20;
      const persistence = 0.3 + Math.sin(rotationOffset * 0.4) * 0.1;
      
      const noise = generateNoise(width, height, scale, 2, persistence, 2);
      
      // Reduce mouse warp strength and base warp strength
      const mouseWarpStrength = 30;
      const warpStrength = 20 + Math.sin(offset * 0.6) * 8 + Math.cos(rotationOffset * 0.6) * 8;
      const warpedNoise = warpNoise(
        noise, 
        warpStrength,
        mousePos.current,
        mouseWarpStrength
      );

      if (ctx) {
        updateCanvasNoise(ctx, warpedNoise, width, height);
        if (containerRef.current) {
          // Add subtle color variations
          const hue = Math.sin(rotationOffset * 0.5) * 10;
          containerRef.current.style.backgroundImage = `
            linear-gradient(
              rgba(${235 + hue}, ${225 + hue}, ${209 + hue}, 0.8),
              rgba(${235 - hue}, ${225 - hue}, ${209 - hue}, 0.8)
            ),
            url(${canvas.toDataURL()})
          `;
        }
      }

      animationFrameRef.current = requestAnimationFrame(animate);
    }

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (containerRef.current) {
        containerRef.current.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      {children}
    </div>
  );
}; 