import React from "react";
import styles from "./AboutUs.module.css";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import brian from  "../../assets/PP/brian.png";
import pranav from  "../../assets/PP/pranav.jpeg";
import daniel from  "../../assets/PP/daniel.jpeg";
import yash from  "../../assets/PP/yash.jpeg";
import ben from  "../../assets/PP/ben.jpeg";
import marco from  "../../assets/PP/marco.jpeg";


import { Footer } from "../../components/Footer/Footer";
import { AnimatedBackground } from "../../components/AnimatedBackground/AnimatedBackground";

export const AboutUs = () => {
  const team = [
    {
      name: "Brian Mason",
      role: "Co-Founder",
      link: "https://briankmason.com/",
      image: brian,
    },
    {
      name: "Pranav Ponnusamy",
      role: "Co-Founder",
      link: "https://pranavponnusamy.com/",
      image:  pranav,
    },
    {
      name: "Daniel Bao",
      role: "Co-Founder",
      link: "https://www.linkedin.com/in/djbao/",
      image: daniel,
    },
    {
      name: "Marco Schönert",
      role: "Co-Founder",
      link: "https://www.linkedin.com/in/marco-sch%C3%B6nert-165965327",
      image: marco,
    },
    {
      name: "Ben Levy",
      role: "Co-Founder",
      link: "https://bmlevy.me/",
      image: ben,
    },
    {
      name: "Yash Dagade",
      role: "Co-Founder",
      link: "https://www.yashdagade.com/",
      image: yash,
    },
  ];

  return (
    <AnimatedBackground>
      <div className={styles.navbar}>
        <Link to="/">
          <img src={logo} className={styles.logo} alt="We Sifted Logo" />
        </Link>
        <div className={styles.navButtons}>
          <Link to="/about">
            <button className={styles.navButton}>our team</button>
          </Link>
          <Link to="/manifesto">
            <button className={styles.navButton}>manifesto</button>
          </Link>
          <a href="mailto:daniel.bao@duke.edu" target="_blank" rel="noreferrer">
            <button className={styles.signUpButton}>make it yours!</button>
          </a>
        </div>
      </div>

      <div className={styles.content}>
        <h1 className={styles.title}>Our Team</h1>
        <div className={styles.teamGrid}>
          {team.map((member) => (
            <a
              key={member.name}
              href={member.link}
              target="_blank"
              rel="noreferrer"
              className={styles.teamMember}
            >
              <img
                src={member.image}
                alt={member.name}
                className={styles.avatar}
              />
              <h3 className={styles.memberName}>{member.name}</h3>
              <p className={styles.memberRole}>{member.role}</p>
            </a>
          ))}
        </div>
      </div>
      <Footer />
    </AnimatedBackground>
  );
};