export default function warpNoise(noise, warpStrength = 10, mousePos = { x: -1, y: -1 }, mouseWarpStrength = 0) {
  const height = noise.length;
  const width = noise[0].length;
  const warped = [];

  for (let y = 0; y < height; y++) {
    warped[y] = [];
    for (let x = 0; x < width; x++) {
      // Calculate base warp
      const warpX = Math.sin(y / warpStrength + x / warpStrength) * warpStrength;
      const warpY = Math.cos(x / warpStrength + y / warpStrength) * warpStrength;

      // Add mouse influence with exponential falloff
      let mouseWarpX = 0;
      let mouseWarpY = 0;
      
      if (mousePos.x >= 0 && mousePos.y >= 0) {
        const dx = x - mousePos.x;
        const dy = y - mousePos.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        // Increase falloff rate by reducing the denominator
        const influence = Math.exp(-distance * distance / (mouseWarpStrength * mouseWarpStrength * 0.5));
        
        mouseWarpX = dx * influence * 2;
        mouseWarpY = dy * influence * 2;
      }

      const sampleX = (x + warpX + mouseWarpX + width) % width;
      const sampleY = (y + warpY + mouseWarpY + height) % height;

      // Bilinear interpolation for smoother sampling
      const x0 = Math.floor(sampleX);
      const x1 = (x0 + 1) % width;
      const y0 = Math.floor(sampleY);
      const y1 = (y0 + 1) % height;

      const sx = sampleX - x0;
      const sy = sampleY - y0;

      const n0 = noise[y0][x0] * (1 - sx) + noise[y0][x1] * sx;
      const n1 = noise[y1][x0] * (1 - sx) + noise[y1][x1] * sx;

      warped[y][x] = n0 * (1 - sy) + n1 * sy;
    }
  }
  return warped;
}
