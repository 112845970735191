import axios from "axios";
import {
  endpoint,
  backendEndpoint,
  deployedBackendEndpoint,
} from "../data/endpoint.data";

export const getDemo = async (url, email) => {
  try {
    console.log("Run");
    const res = await axios.get(`${endpoint}/demo/url_to_bills`, {
      params: { url, email },
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { number, summary, splits } = res.data;
    const billData = await axios.get(
      `${deployedBackendEndpoint}/bills/${parseInt(number)}`
    );
    console.log(billData?.data);
    const { title, updateDate } = billData?.data?.bill;

    return {
      number,
      summary,
      title,
      splits,
      updateDate,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
