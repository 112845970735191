import React, { useState } from "react";
import styles from "./Landing.module.css";
import logo from "../../assets/logo.png";
import { getDemo } from "../../api/demo.api";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { AnimatedBackground } from "../../components/AnimatedBackground/AnimatedBackground";

export const Landing = () => {
  const [websiteURL, setWebsiteURL] = useState("");
  const [email, setEmail] = useState("");
  const [billData, setBillData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [didGetExample, setDidGetExample] = useState(false);
  const [step, setStep] = useState(1); // 1 for website URL, 2 for email

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  const handleSubmit = async () => {
    if (step === 1 && websiteURL.trim() !== "") {
      setStep(2);
    } else if (step === 2 && email.trim() !== "" && websiteURL.trim() !== "") {
      const formattedURL =
        websiteURL.startsWith("http://") || websiteURL.startsWith("https://")
          ? websiteURL
          : `https://${websiteURL}`;

      setIsLoading(true);
      const { number, summary, title, splits, updateDate } = await getDemo(
        formattedURL.trim(),
        email.trim()
      );
      setBillData({ number, summary, title, splits, updateDate });
      setIsLoading(false);
      setDidGetExample(true);
    }
  };

  return (
    <AnimatedBackground>
      <div className={styles.navbar}>
        <Link to="/">
          <img src={logo} className={styles.logo} alt="We Sifted Logo" />
        </Link>
        <div className={styles.navButtons}>
          <Link to="/about">
            <button className={styles.navButton}>our team</button>
          </Link>
          <Link to="/manifesto">
            <button className={styles.navButton}>manifesto</button>
          </Link>
          <a href="mailto:daniel.bao@duke.edu" target="_blank" rel="noreferrer">
            <button className={styles.signUpButton}>make it yours!</button>
          </a>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <h2 className={styles.subtitle}>
            legislations are <span className={styles.italics}>fickle</span>.{" "}
            <span className={styles.golden}>
              regulations are <span className={styles.italics}>fiddly</span>.
            </span>
          </h2>
          <h1 className={styles.title}>
            you shouldn't <span className={styles.italics}>have</span> to worry.
          </h1>
          <p className={styles.description}>
            get quarterly <span className={styles.bold}>curated bill updates</span>{" "}
            <span className={styles.italics}>(that matter)</span> in your inbox,
            tailored to <span className={styles.bold}>your profile,</span>{" "}
            without the overwhelm.{" "}
          </p>
          <div className={styles.inputContainer}>
            {step === 1 ? (
              <input
                type="text"
                className={styles.websiteInput}
                placeholder="enter your company website"
                value={websiteURL}
                onChange={(e) => setWebsiteURL(e.target.value)}
                required
              />
            ) : (
              <input
                type="email"
                className={styles.websiteInput}
                placeholder="enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            )}
            <button
              className={styles.websiteInputButton}
              onClick={handleSubmit}
              disabled={
                (step === 1 && !websiteURL.trim()) ||
                (step === 2 && !email.trim())
              }
            >
              {step === 1 ? "next" : "try it"}
            </button>
          </div>
        </div>
        <div className={styles.rightContent}>
          <h1 className={styles.rightContentTitle}>
            {isLoading
              ? "getting your bill..."
              : didGetExample
              ? "so, we sifted this..."
              : "here's an example..."}
          </h1>
          {!didGetExample && !isLoading && (
            <p className={styles.demoText}>
              Want to get your own custom bill? Enter your company website on
              the left.
            </p>
          )}
          {isLoading ? (
            <div className={styles.loadingBill}>
              <Oval
                color="#947D47"
                secondaryColor="#5F5747"
                height={150}
                width={150}
              />
            </div>
          ) : (
            <div className={styles.bill}>
              <div className={styles.upperBillBar}>
                <h3 className={styles.upperBillBarText}>
                  #{didGetExample ? billData?.number : "5061"}
                </h3>
                <h3 className={styles.upperBillBarText}>
                  {didGetExample
                    ? formatDate(billData?.updateDate)
                    : "Sep 23, 2024"}
                </h3>
              </div>
              <h2 className={styles.billTitle}>
                {didGetExample
                  ? billData?.title
                  : "21st Century Workforce Partnerships Act"}
              </h2>
              <p>
                {didGetExample
                  ? billData?.summary
                  : "Bill 5061 focuses on enhancing education and career training programs, particularly through partnerships between educational agencies and industry. This bill supports initiatives that provide funding for career pathways programs, including training for educators and opportunities for students to gain practical experience in coding and technology. For Codeocity, this bill could significantly impact their operations by potentially providing access to grants that support their coding courses and educational programs. It aligns with their mission to make coding education accessible and engaging, allowing them to expand their offerings and reach more students while fostering partnerships that enhance their curriculum."}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={styles.bottomContent}>
        <p>We Sifted was created by:&nbsp;</p>
        <a
          href="https://briankmason.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Brian Mason
        </a>
        ,&nbsp;
        <a
          href="https://pranavponnusamy.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Pranav Ponnusamy
        </a>
        ,&nbsp;
        <a
          href="https://www.linkedin.com/in/djbao/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Daniel Bao
        </a>
        ,&nbsp;
        <a
          href="https://www.linkedin.com/in/marco-sch%C3%B6nert-165965327"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Marco Schönert
        </a>
        ,&nbsp;
        <a
          href="https://bmlevy.me/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Ben Levy
        </a>
        ,&nbsp;and&nbsp;
        <a
          href="https://www.yashdagade.com/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Yash Dagade
        </a>
      </div>
      <Footer />
    </AnimatedBackground>
  );
};
